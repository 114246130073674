import { useSelector } from 'react-redux'
import { isMobile } from 'utils/getDevice'

const handlersCms = () => {
  const form_hero = useSelector(state => state.cms.form_hero)

  return {
    title: form_hero?.title.html,
    description: form_hero?.description.text,
    image: isMobile() ? form_hero?.mobileBackground.image.path : form_hero?.background.image.path,
  }
}

export default handlersCms
