import Header from 'components/Header'
import Sidebar from 'components/Sidebar'
import Menu from 'components/Menu'
import Footer from 'components/Footer'

import useReCaptcha from 'hooks/useReCaptcha'

import FormHero from 'components/FormHero'
import SectionPrevOld from 'components/SectionPrevOld'
import SectionPartners from 'components/SectionPartners'
import SectionPrevTech from 'components/SectionPrevTech'
import SectionPlans from 'components/SectionPlans'
import SectionApps from 'components/SectionApps'
import SectionHealth from 'components/SectionHealth'
import SectionFaq from 'components/SectionFaq'
import SectionForm from 'components/SectionForm'

import enhancer from './hooks'

const FormHeroLanding = ({title, description, image}) => {
  useReCaptcha()

  return (
    <div>
      <Header />
      <Menu />
      <Sidebar>
        <SectionForm customClass="sidebar" />
      </Sidebar>
      <FormHero title={title} description={description} image={image}/>
      <SectionPrevOld />
      <SectionPartners />
      <SectionPrevTech />
      <SectionApps />
      <SectionPlans />
      <SectionHealth />
      <SectionForm />
      <SectionFaq />
      <Footer />
    </div>
  )
}

export default enhancer(FormHeroLanding)
